import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title variant="hero" className="text-center">
                  Terms & Conditions
                </Title>
                <Box mt={["40px", null, "50px"]}>
                  <Text variant="small">Last updated: Feb 14, 2022</Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Interpretation
                  </Title>
                  <Text variant="small">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Definitions
                  </Title>
                  <Text variant="small">
                    For the purposes of these Terms and Conditions:
                  </Text>
                  <List>
                    <li>
                      Affiliate means an entity that controls, is controlled by
                      or is under common control with a party, where "control"
                      means ownership of 50% or more of the shares, equity
                      interest or other securities entitled to vote for election
                      of directors or other managing authority.
                    </li>
                    <li>Country refers to: United States</li>
                    <li>
                      Company (referred to as either "the Company", "We", "Us"
                      or "Our" in this Agreement) refers to NorthCtrl LLC, 11700
                      Mukilteo Speedway Ste 201-5012 Mukilteo, WA 98275.
                    </li>
                    <li>
                      Device means any device that can access the Service such
                      as a computer, a cellphone or a digital tablet.
                    </li>
                    <li>
                      Free Trial refers to a limited period of time that may be
                      free when purchasing a Subscription.
                    </li>
                    <li>Service refers to the Website.</li>
                    <li>
                      Subscriptions refer to the services or access to the
                      Service offered on a subscription basis by the Company to
                      You.
                    </li>
                    <li>
                      Terms and Conditions (also referred as "Terms") mean these
                      Terms and Conditions that form the entire agreement
                      between You and the Company regarding the use of the
                      Service.
                    </li>
                    <li>
                      Third-party Social Media Service means any services or
                      content (including data, information, products or
                      services) provided by a third-party that may be displayed,
                      included or made available by the Service.
                    </li>
                    <li>
                      Website refers to LoanerHub, accessible from
                      https://loanerhub.com
                    </li>
                    <li>
                      You means the individual accessing or using the Service,
                      or the company, or other legal entity on behalf of which
                      such individual is accessing or using the Service, as
                      applicable.
                    </li>
                  </List>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Acknowledgment
                  </Title>
                  <Text variant="small">
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                    <br />
                    <br />
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and
                    Conditions. These Terms and Conditions apply to all
                    visitors, users and others who access or use the Service.
                    <br />
                    <br />
                    By accessing or using the Service You agree to be bound by
                    these Terms and Conditions. If You disagree with any part of
                    these Terms and Conditions then You may not access the
                    Service.
                    <br />
                    <br />
                    You represent that you are over the age of 18. The Company
                    does not permit those under 18 to use the Service.
                    <br />
                    <br />
                    Your access to and use of the Service is also conditioned on
                    Your acceptance of and compliance with the Privacy Policy of
                    the Company. Our Privacy Policy describes Our policies and
                    procedures on the collection, use and disclosure of Your
                    personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the
                    law protects You. Please read Our Privacy Policy carefully
                    before using Our Service.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Subscription period
                  </Title>
                  <Text variant="small">
                    The Service or some parts of the Service are available only
                    with a paid Subscription. You will be billed in advance on a
                    recurring and periodic basis (such as daily, weekly, monthly
                    or annually), depending on the type of Subscription plan you
                    select when purchasing the Subscription.
                    <br />
                    <br />
                    At the end of each period, Your Subscription will
                    automatically renew under the exact same conditions unless
                    You cancel it or the Company cancels it.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Subscription cancellations
                  </Title>
                  <Text variant="small">
                    You may cancel Your Subscription renewal either through Your
                    Account settings page or by contacting the Company. You will
                    not receive a refund for the fees You already paid for Your
                    current Subscription period and You will be able to access
                    the Service until the end of Your current Subscription
                    period.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Billing
                  </Title>
                  <Text variant="small">
                    You shall provide the Company with accurate and complete
                    billing information including full name, address, state, zip
                    code, telephone number, and a valid payment method
                    information.
                    <br />
                    <br />
                    Should automatic billing fail to occur for any reason, the
                    Company will issue an electronic invoice indicating that you
                    must proceed manually, within a certain deadline date, with
                    the full payment corresponding to the billing period as
                    indicated on the invoice.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Fee Changes
                  </Title>
                  <Text variant="small">
                    The Company, in its sole discretion and at any time, may
                    modify the Subscription fees. Any Subscription fee change
                    will become effective at the end of the then-current
                    Subscription period. The Company will provide You with
                    reasonable prior notice of any change in Subscription fees
                    to give You an opportunity to terminate Your Subscription
                    before such change becomes effective.
                    <br />
                    <br />
                    Your continued use of the Service after the Subscription fee
                    change comes into effect constitutes Your agreement to pay
                    the modified Subscription fee amount.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Refunds
                  </Title>
                  <Text variant="small">
                    <br />
                    <b>
                      NONREFUNDABLE AMOUNT <br />I UNDERSTAND THAT I HAVE PAID
                      OR AM OBLIGATED TO PAY THE SUBSCRIPTION FEES AS AN
                      INITIATION OR MEMBERSHIP FEE, AND THAT UNDER NO
                      CIRCUMSTANCES IS ANY PORTION OF THIS AMOUNT REFUNDABLE.
                    </b>
                    <br />
                    <br />
                    Certain refund requests for Subscriptions may be considered
                    by the Company on a case-by-case basis and granted at the
                    sole discretion of the Company.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Free Trial
                  </Title>
                  <Text variant="small">
                    The Company may, at its sole discretion, offer a
                    Subscription with a Free trial for a limited period of time.
                    You may be required to enter Your billing information in
                    order to sign up for the Free trial.
                    <br />
                    <br />
                    If You do enter Your billing information when signing up for
                    a Free Trial, You will not be charged by the Company until
                    the Free trial has expired. On the last day of the Free
                    Trial period, unless You cancelled Your Subscription, You
                    will be automatically charged the applicable Subscription
                    fees for the type of Subscription You have selected.
                    <br />
                    <br />
                    At any time and without notice, the Company reserves the
                    right to (i) modify the terms and conditions of the Free
                    Trial offer, or (ii) cancel such Free trial offer.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Links to Other Websites
                  </Title>
                  <Text variant="small">
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by the Company.
                    <br />
                    <br />
                    The Company has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third party web sites or services. You
                    further acknowledge and agree that the Company shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with the use of or reliance on any such content,
                    goods or services available on or through any such web sites
                    or services.
                    <br />
                    <br />
                    We strongly advise You to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that You visit.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Termination
                  </Title>
                  <Text variant="small">
                    We may terminate or suspend Your access immediately, without
                    prior notice or liability, for any reason whatsoever,
                    including without limitation if You breach these Terms and
                    Conditions.
                    <br />
                    <br />
                    Upon termination, Your right to use the Service will cease
                    immediately.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Limitation of Liability
                  </Title>
                  <Text variant="small">
                    Notwithstanding any damages that You might incur, the entire
                    liability of the Company and any of its suppliers under any
                    provision of this Terms and Your exclusive remedy for all of
                    the foregoing shall be limited to the amount actually paid
                    by You through the Service or 100 USD if You haven't
                    purchased anything through the Service.
                    <br />
                    <br />
                    To the maximum extent permitted by applicable law, in no
                    event shall the Company or its suppliers be liable for any
                    special, incidental, indirect, or consequential damages
                    whatsoever (including, but not limited to, damages for loss
                    of profits, loss of data or other information, for business
                    interruption, for personal injury, loss of privacy arising
                    out of or in any way related to the use of or inability to
                    use the Service, third-party software and/or third-party
                    hardware used with the Service, or otherwise in connection
                    with any provision of this Terms), even if the Company or
                    any supplier has been advised of the possibility of such
                    damages and even if the remedy fails of its essential
                    purpose.
                    <br />
                    <br />
                    Some states do not allow the exclusion of implied warranties
                    or limitation of liability for incidental or consequential
                    damages, which means that some of the above limitations may
                    not apply. In these states, each party's liability will be
                    limited to the greatest extent permitted by law.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    "AS IS" and "AS AVAILABLE" Disclaimer
                  </Title>
                  <Text variant="small">
                    The Service is provided to You "AS IS" and "AS AVAILABLE"
                    and with all faults and defects without warranty of any
                    kind. To the maximum extent permitted under applicable law,
                    the Company, on its own behalf and on behalf of its
                    Affiliates and its and their respective licensors and
                    service providers, expressly disclaims all warranties,
                    whether express, implied, statutory or otherwise, with
                    respect to the Service, including all implied warranties of
                    merchantability, fitness for a particular purpose, title and
                    non-infringement, and warranties that may arise out of
                    course of dealing, course of performance, usage or trade
                    practice. Without limitation to the foregoing, the Company
                    provides no warranty or undertaking, and makes no
                    representation of any kind that the Service will meet Your
                    requirements, achieve any intended results, be compatible or
                    work with any other software, applications, systems or
                    services, operate without interruption, meet any performance
                    or reliability standards or be error free or that any errors
                    or defects can or will be corrected.
                    <br />
                    <br />
                    Without limiting the foregoing, neither the Company nor any
                    of the company's provider makes any representation or
                    warranty of any kind, express or implied: (i) as to the
                    operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon; (ii) that the Service will be uninterrupted or
                    error-free; (iii) as to the accuracy, reliability, or
                    currency of any information or content provided through the
                    Service; or (iv) that the Service, its servers, the content,
                    or e-mails sent from or on behalf of the Company are free of
                    viruses, scripts, trojan horses, worms, malware, timebombs
                    or other harmful components.
                    <br />
                    <br />
                    Some jurisdictions do not allow the exclusion of certain
                    types of warranties or limitations on applicable statutory
                    rights of a consumer, so some or all of the above exclusions
                    and limitations may not apply to You. But in such a case the
                    exclusions and limitations set forth in this section shall
                    be applied to the greatest extent enforceable under
                    applicable law.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Governing Law
                  </Title>
                  <Text variant="small">
                    The laws of the Country, excluding its conflicts of law
                    rules, shall govern this Terms and Your use of the Service.
                    Your use of the Application may also be subject to other
                    local, state, national, or international laws.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Disputes Resolution
                  </Title>
                  <Text variant="small">
                    If You have any concern or dispute about the Service, You
                    agree to first try to resolve the dispute informally by
                    contacting the Company.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    United States Federal Government End Use Provisions
                  </Title>
                  <Text variant="small">
                    If You are a U.S. federal government end user, our Service
                    is a "Commercial Item" as that term is defined at 48 C.F.R.
                    §2.101.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    United States Legal Compliance
                  </Title>
                  <Text variant="small">
                    You represent and warrant that (i) You are not located in a
                    country that is subject to the United States government
                    embargo, or that has been designated by the United States
                    government as a "terrorist supporting" country, and (ii) You
                    are not listed on any United States government list of
                    prohibited or restricted parties.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Severability
                  </Title>
                  <Text variant="small">
                    If any provision of these Terms is held to be unenforceable
                    or invalid, such provision will be changed and interpreted
                    to accomplish the objectives of such provision to the
                    greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Waiver
                  </Title>
                  <Text variant="small">
                    Except as provided herein, the failure to exercise a right
                    or to require performance of an obligation under this Terms
                    shall not effect a party's ability to exercise such right or
                    require such performance at any time thereafter nor shall be
                    the waiver of a breach constitute a waiver of any subsequent
                    breach.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Translation Interpretation
                  </Title>
                  <Text variant="small">
                    These Terms and Conditions may have been translated if We
                    have made them available to You on our Service. You agree
                    that the original English text shall prevail in the case of
                    a dispute.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Cookies
                  </Title>
                  <Text variant="small">
                    We employ the use of cookies. By accessing LoanerHub, you
                    agreed to use cookies in agreement with the NorthCtrl's
                    Privacy Policy.
                    <br /> <br />
                    Most interactive websites use cookies to let us retrieve the
                    user’s details for each visit. Cookies are used by our
                    website to enable the functionality of certain areas to make
                    it easier for people visiting our website. Some of our
                    affiliate/advertising partners may also use cookies.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Changes to These Terms and Conditions
                  </Title>
                  <Text variant="small">
                    We reserve the right, at Our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material
                    We will make reasonable efforts to provide at least 30 days'
                    notice prior to any new terms taking effect. What
                    constitutes a material change will be determined at Our sole
                    discretion.
                    <br />
                    <br />
                    By continuing to access or use Our Service after those
                    revisions become effective, You agree to be bound by the
                    revised terms. If You do not agree to the new terms, in
                    whole or in part, please stop using the website and the
                    Service.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Contact Us
                  </Title>
                  <Text variant="small">
                    If you have any questions about these Terms and Conditions,
                    You can contact us:
                    <br />
                    <br />
                    By email: support@loanerhub.com
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
